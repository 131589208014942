import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Timeless Events
			</title>
			<meta name={"description"} content={"Удосконалення ваших моментів"} />
			<meta property={"og:title"} content={"Головна | Timeless Events"} />
			<meta property={"og:description"} content={"Удосконалення ваших моментів"} />
			<meta property={"og:image"} content={"https://blumoran.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blumoran.com/img/t-letter-icon-2048x2048-e0oohbpi.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.6) 100%),url(https://blumoran.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="140px 0 88px 0"
			quarkly-title="Hero-21"
			padding="88px 0 88px 0"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				flex-wrap="wrap"
				align-items="center"
				justify-content="center"
			/>
			<Text
				color="--primary"
				font="--headline1"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				margin="0px 0px 42px 0px"
				lg-width="100%"
				md-width="100%"
				lg-text-align="center"
				lg-margin="0px 0px 20px 0px"
				lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
				letter-spacing="10px"
			>
				Timeless Events
			</Text>
			<Text
				lg-text-align="center"
				text-align="center"
				width="80%"
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--lead"
				lg-width="100%"
			>
				У Timeless Events ваші особливі події - це наша пристрасть. Ми присвячуємо себе перетворенню ваших задумів у красиво виконану реальність, гарантуючи, що кожна подія буде такою ж позачасовою, як і спогади, які вона створює. Від пишних весіль до професійних корпоративних заходів та невимушених зустрічей - ми тут, щоб зробити кожну мить бездоганною та незабутньою.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати Timeless Events?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Індивідуальний досвід
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ми віримо, що кожна подія повинна відображати особистість та бачення організатора. Наша команда є експертом у налаштуванні кожної деталі відповідно до вашого унікального стилю та вподобань.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Виняткові стандарти
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ми дотримуємося найвищих стандартів у всіх аспектах наших послуг, гарантуючи, що кожна деталь заходу, від місця проведення до декору, буде бездоганною.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Комплексна підтримка
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					З того моменту, як ви звертаєтесь до нас, ми дбаємо про кожну деталь, дозволяючи вам розслабитись і насолоджуватись заходом без жодного стресу.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Універсальне планування
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Незалежно від розміру та масштабу вашого заходу, наша команда готова провести його з елегантністю та професіоналізмом.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});